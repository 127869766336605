//导入其他api文件
import * as auth  from './auth';
import * as industry  from './industry';
import * as project  from './project';
import * as athlete  from './athlete';
import * as team  from './team';
import * as ip  from './ip';
import * as ipAll  from './ipAll';
import * as history  from './history';
import * as sponsor  from './sponsor';
import * as alias  from './alias';
import * as operateLog  from './operateLog';
import * as cpe  from './cpe';
import * as kol  from './kol';
import * as media  from './media';

export default {
    auth,
    industry,
    project,
    athlete,
    team,
    ip,
    ipAll,
    history,
    sponsor,
    alias,
    operateLog,
    cpe,
    kol,
    media,
}
