// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/sponsor/alias';

// 定义后台接口地址
const url = {
    list: baseUrl + '/list',
    save: baseUrl + '/save',
    del: baseUrl + '/del',
}

// 对外导出操作方法
export function getList(param) {
    return request.get(url.list, param);
}
export function save(param) {
    return request.post(url.save, param);
}
export function del(param) {
    return request.post(url.del, param);
}