// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/ip';

// 定义后台接口地址
const url = {
    list: baseUrl + '/list',
    one: baseUrl,
    save: baseUrl + '/save',
    del: baseUrl + '/del',
}

// 对外导出操作方法
export function getList(param) {
    return request.get(url.list, param);
}
export function getOne(id){
    return request.get(url.one + "/" + id);
}
export function save(param){
    return request.post(url.save, param);
}
export function del(param){
    return request.post(url.del, param);
}