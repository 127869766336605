<template>
  <div id="app">
    <router-view />
    <div class="bruce">
      <ul class="bubble-bgwall">
        <li>Pinball</li>
        <li>Pinball</li>
        <li>Pinball</li>
        <li>Pinball</li>
        <li>Pinball</li>
        <li>Pinball</li>
        <li>Pinball</li>
        <li>Pinball</li>
        <li>Pinball</li>
        <li>Pinball</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>


<style lang="less">
@import './assets/css/background.css';

* {
  ::-webkit-scrollbar {
    background: linear-gradient(to bottom);
    width: 0px;
    height: 5px;
  }

  /* 滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6));
    border-radius: 3px;
  }

  margin: 0;
  padding: 0;
  //页面全灰
  // -webkit-filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -ms-filter: grayscale(100%);
  // -o-filter: grayscale(100%);
  // filter: grayscale(100%);
  // filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

body {
  padding: 0;
}

#app {
  height: auto;
  font-family: SunTi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;

  .lizi {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
