import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Index',
    component: resolve => (require(["@/views"], resolve)),
    children:[
      {
        path: 'home',
        name: 'Home',
        component: resolve => (require(["@/views/Home"], resolve))
      },
      {
        path: 'industry',
        name: 'Industry',
        component: resolve => (require(["@/views/Industry"], resolve))
      },
      {
        path: 'project',
        name: 'Project',
        component: resolve => (require(["@/views/Project"], resolve))
      },
      {
        path: 'athlete',
        name: 'Athlete',
        component: resolve => (require(["@/views/Athlete"], resolve))
      },
      {
        path: 'team',
        name: 'Team',
        component: resolve => (require(["@/views/Team"], resolve))
      },
      {
        path: 'ip',
        name: 'Ip',
        component: resolve => (require(["@/views/Ip"], resolve))
      },
      {
        path: 'kol',
        name: 'Kol',
        component: resolve => (require(["@/views/Kol"], resolve))
      },
      {
        path: 'media',
        name: 'Media',
        component: resolve => (require(["@/views/Media"], resolve))
      },
      {
        path: 'sponsor_history',
        name: 'SponsorHistory',
        component: resolve => (require(["@/views/SponsorHistory"], resolve))
      },
      {
        path: 'sponsor',
        name: 'Sponsor',
        component: resolve => (require(["@/views/Sponsor"], resolve))
      },
      {
        path: 'operate_log',
        name: 'OperateLog',
        component: resolve => (require(["@/views/OperateLog"], resolve))
      },
      {
        path: '/modulus/standard',
        name: 'Standard',
        component: resolve => (require(["@/views/CPE/standard.vue"], resolve))
      },
      {
        path: '/modulus/sports_category',
        name: 'SportsCategory',
        component: resolve => (require(["@/views/CPE/sportsCategory.vue"], resolve))
      },
      {
        path: '/modulus/channel',
        name: 'Channel',
        component: resolve => (require(["@/views/CPE/channel.vue"], resolve))
      },
      {
        path: '/modulus/ip_category',
        name: 'IpCategory',
        component: resolve => (require(["@/views/CPE/ipCategory.vue"], resolve))
      },
      {
        path: '/modulus/level',
        name: 'Level',
        component: resolve => (require(["@/views/CPE/level.vue"], resolve))
      },
      {
        path: '/modulus/ip',
        name: 'CPEIp',
        component: resolve => (require(["@/views/CPE/ip.vue"], resolve))
      },
      {
        path: '/cpe',
        name: 'CPE',
        component: resolve => (require(["@/views/CPE/cpe.vue"], resolve))
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: resolve => (require(["@/views/Login"], resolve))
  },
  
]

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  mode: 'history', // 去掉url中的#   history | hash
  scrollBehavior: () => ({ y: 0 }),
  routes
})
