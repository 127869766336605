export default {

    getAllMonths() {
        const startDate = new Date('2022-01-01');
        const currentDate = new Date();
        let dateIterator = startDate;
        const allMonths = [];
      
        while (dateIterator <= currentDate) {
          const year = dateIterator.getFullYear();
          const month = dateIterator.getMonth() + 1;
          const formattedMonth = month < 10 ? `0${month}` : month;
          const formattedDate = `${year}-${formattedMonth}`;
          allMonths.push(formattedDate);
          dateIterator.setMonth(dateIterator.getMonth() + 1);
        }

        
      
        return allMonths.reverse();
    }
      
    
}