// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/ipAll';

// 定义后台接口地址
const url = {
    list: baseUrl + '/list',
}

// 对外导出操作方法
export function getList(param) {
    return request.get(url.list, param);
}