// 导入HTTP请求工具
import request from "@/utils/request";

const baseUrl = '/api/cpe';

// 定义后台接口地址
const url = {
    standardList: baseUrl + '/standard/list',
    standardSave: baseUrl + '/standard/save',
    
    sportsCategoryList: baseUrl + '/sports/category/list',
    sportsCategorySave: baseUrl + '/sports/category/save',
    
    channelList: baseUrl + '/channel/list',
    channelSave: baseUrl + '/channel/save',
    
    ipCategoryList: baseUrl + '/ip/category/list',
    ipCategorySave: baseUrl + '/ip/category/save',
    
    levelList: baseUrl + '/level/list',
    levelSave: baseUrl + '/level/save',
    
    ipList: baseUrl + '/ip/list',
    ipSave: baseUrl + '/ip/save',

    list: baseUrl + '/list',
    save: baseUrl + '/save',
    saveMonthly: baseUrl + '/save/monthly',
}

// 对外导出操作方法
export function standardList(param){
    return request.get(url.standardList, param);
}

export function standardSave(param){
    return request.post(url.standardSave, param);
}

export function sportsCategoryList(param){
    return request.get(url.sportsCategoryList, param);
}

export function sportsCategorySave(param){
    return request.post(url.sportsCategorySave, param);
}

export function channelList(param){
    return request.get(url.channelList, param);
}

export function channelSave(param){
    return request.post(url.channelSave, param);
}

export function ipCategoryList(param){
    return request.get(url.ipCategoryList, param);
}

export function ipCategorySave(param){
    return request.post(url.ipCategorySave, param);
}

export function levelList(param){
    return request.get(url.levelList, param);
}

export function levelSave(param){
    return request.post(url.levelSave, param);
}

export function ipList(param){
    return request.get(url.ipList, param);
}

export function ipSave(param){
    return request.post(url.ipSave, param);
}

export function list(param){
    return request.get(url.list, param);
}

export function save(param){
    return request.post(url.save, param);
}

export function saveMonthly(param){
    return request.post(url.saveMonthly, param);
}
